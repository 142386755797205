<template>
  <transition name="rec">
    <el-dialog
      title="Восстановление пароля"
      :visible="visible"
      width="526px"
      custom-class="home-modal"
      @close="beforeClose"
    >
      <div class="modal-wrapper">
        <div class="passRecovery">
          <!-- ПЕРВОЕ окно -->
          <div v-if="!confirmedSms && !success" class="passRecovery__request">
            <div class="passRecovery__request-content">
              <p class="request__content-text p-modal">
                Для восстановления пароля мы вышлем вам SMS с проверочным кодом
              </p>
            </div>

            <el-form
              ref="passRecovery"
              class="passRecoveryForm"
              :model="passRecovery"
              :rules="rules"
              :validate-on-rule-change="false"
            >
              <IqInputForm
                v-model="passRecovery.phone"
                v-mask="'+7 (###) ###-##-##'"
                ownclass="no-zoom"
                input-width="100%"
                type="tel"
                placeholder="Введите номер телефона"
                prop="phone"
                :rules="rules.phone"
              />

              <!-- button -->
              <div class="dispatchButton">
                <iq-button
                  color="primary"
                  @onClick="dispatchCode('passRecovery')"
                >
                  Выслать код
                </iq-button>
              </div>
            </el-form>
          </div>

          <!-- ВТОРОЕ окно -->
          <div v-if="confirmedSms && !success" class="newPassword">
            <div class="newPassword__content">
              <div class="newPassword__content-dispatchNumber">
                <p class="dispatchNumber-text p-modal">
                  Мы отправили код на номер
                </p>
                <span class="dispatchNumber-number">
                  {{ phoneRegex(passRecovery.phone) }}
                </span>
              </div>

              <el-link
                type="primary"
                :underline="false"
                class="newPassword__content-link"
                @click="backToNumber"
              >
                Изменить номер
              </el-link>
              <!-- временный вывод SMS кода -->
              <div class="temporaryCode">
                Код для подтверждения учетной записи:
                <span class="smsCode">{{ code }}</span>
              </div>
            </div>

            <el-form
              ref="newPass_form"
              class="newPass_form"
              :model="newPass_form"
              :rules="rules"
              :validate-on-rule-change="false"
            >
              <IqInputForm
                v-model="newPass_form.code"
                ownclass="no-zoom"
                input-width="100%"
                placeholder="Код подтверждения"
                prop="code"
                :rules="rules.code"
              />

              <IqInputForm
                v-model="newPass_form.password"
                ownclass="no-zoom"
                input-width="100%"
                placeholder="Придумайте новый пароль"
                prop="password"
                :rules="rules.password"
              />

              <IqInputForm
                v-model="newPass_form.passwordCheck"
                ownclass="no-zoom"
                input-width="100%"
                placeholder="Повторите пароль"
                prop="passwordCheck"
                :rules="rules.passRecCheck"
              />

              <!-- button -->
              <div class="dispatchButton">
                <iq-button
                  color="primary"
                  @onClick="createNewPass('newPass_form')"
                >
                  Установить пароль
                </iq-button>
              </div>
            </el-form>

            <div class="passRecovery-resendingText">
              <TimerButton
                :timer-interval="timerInterval"
                @button-clicked="reDispatchCode"
              />
            </div>
          </div>

          <!-- ТРЕТЬЕ окно -->
          <div v-if="success" class="passRecovery__success">
            <div class="passRecovery__success-test">
              Пароль успешно установлен.
            </div>

            <div class="dispatchButton">
              <iq-button color="primary" @onClick="beforeClose"> Ок </iq-button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </transition>
</template>

<script>
import IqButton from '@/views/ui/components/buttons/IqButton'
import IqInputForm from '@/views/ui/components/input/IqInputForm'
import TimerButton from '@/views/ui/components/buttons/TimerButton'

import { phoneRegex } from '@/core/helpers'
import { timerDiff } from '@/core'
import Api from '@/api'
import rulesMixin from '@/views/login/data/rulesMixin'

export default {
  name: 'DialogPasswordRecovery',
  components: {
    IqInputForm,
    IqButton,
    TimerButton,
  },
  mixins: [rulesMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      phoneRegex,
      confirmedSms: false,
      success: false,

      // ! временный вывод SMS кода
      code: '',

      smsBlockedUntil: '',
      passRecovery: {
        phone: '',
      },

      newPass_form: {
        code: '',
        password: '',
        passwordCheck: '',
      },
    }
  },
  computed: {
    timerInterval() {
      return timerDiff(this.smsBlockedUntil)
    },
  },
  methods: {
    dispatchCode(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          let formData = new FormData()

          formData.append('phone', this.passRecovery.phone)
          await Api.auth
            .sms(formData)
            .then(res => {
              this.confirmedSms = true
              this.code = res.data.code
              this.passRecovery.phone = res.data.phone
              this.smsBlockedUntil = res.data.sms_blocked_until
            })
            .catch(err => {
              console.log('Error RESET_SMS: ', err?.response?.data?.[0].message)
            })
        } else {
          return false
        }
      })
    },
    createNewPass(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          let formData = new FormData()

          formData.append('code', this.newPass_form.code)
          formData.append('phone', this.passRecovery.phone)
          formData.append('password', this.newPass_form.password)
          await Api.auth
            .reset(formData)
            .then(res => {
              res.status === 200 ? (this.success = true) : false
            })
            .catch(err => {
              console.log('Error RESET_SMS: ', err?.response?.data?.[0].message)
            })
        } else {
          return false
        }
      })
    },
    reDispatchCode() {
      let formData = new FormData()

      formData.append('phone', this.passRecovery.phone)
      Api.auth
        .sms(formData)
        .then(res => {
          this.code = res.data.code
          this.smsBlockedUntil = res.data.sms_blocked_until
        })
        .catch(err => {
          console.log('Error RESET_SMS: ', err?.response?.data?.[0].message)
        })
    },

    backToNumber() {
      this.confirmedSms = false
    },
    beforeClose() {
      this.success = false
      this.$emit('close')
    },
  },
}
</script>

<style lang="sass" scoped>
.modal-wrapper
  .passRecovery
    &__request
      &-content
        margin-bottom: 20px
      .passRecoveryForm
        ::v-deep .iq-input-form.el-form-item
          margin-bottom: 20px !important
    &-resendingText
      color: $color-blue-light
    .newPassword
      &__content
        &-dispatchNumber
          display: flex
          align-items: center
          column-gap: 10px
          margin-bottom: 10px
          .dispatchNumber-number
            font-size: 20px
        &-link
          display: inline-block
          margin-bottom: 20px
          color: $color-blue-light
        .temporaryCode
          display: block
      .newPass_form
        ::v-deep .iq-input-form.el-form-item
          margin-bottom: 20px !important
        .dispatchButton
          margin: 30px 0 20px
    &__success-test
      font-size: 20px
      line-height: 20px
      margin-bottom: 10px
</style>
